<template>
  <div v-if="types">
    <request-type />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RequestType from "../components/admin/RequestType";

export default {
  name: "reqType",
  components: {
    RequestType
  },

  async created() {
    await this.retriveTypes();
  },

  computed: {
    ...mapState({
      types: "types"
    })
  },

  methods: {
    ...mapActions(["retriveTypes"])
  }
};
</script>