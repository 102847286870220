<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
    <div class=" my-border">
      <v-card-title class="py-0 my-0 customlightgray">
        <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="4" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{$t("Request Types")}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{types.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="8" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn v-if="$checkPermission('Requests.Update')" depressed  class="ma-auto pa-auto mr-sm-1 custom-color-accent" @click="resetSerialNumber">{{$t('Reset All Serial Number')}}</v-btn>
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="dialog=true;editedIndex=-1" v-if="$checkPermission('Requests.Create')">{{$t("New Type")}}</v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="800px">
        <v-card class="customlightgray">
          <v-card-title class="justify-center ma-0 gray">
            <span class="font-weight-light">{{ formTitle }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.request_type"
                    :counter="max"
                    dense outlined hide-details
                    :label="$t('requesttype')"
                    :placeholder="$t('requesttype')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.description_en"
                    :counter="max"
                    :label="$t('Description English')"
                    dense outlined hide-details
                    :placeholder="$t('Description English')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.description_fr"
                    :counter="max"
                    :label="$t('Description French')"
                    dense outlined hide-details
                    :placeholder="$t('Description French')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="security()"
                    dense outlined hide-details
                    :label="$t('securityclass')"
                    :placeholder="$t('securityclass')"
                    v-model="editedItem.security_class"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    filled
                    placeholder="A-YYYY-XXXID"
                    :label="$t('requestid')"
                    dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.last_used_serial_number"
                    readonly
                    filled
                    :label="$t('Last Used Serial Number')"
                    :placeholder="$t('Last Used Serial Number')"
                    dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.request_id_template"
                    :label="$t('Request ID Template')"
                    :placeholder="$t('Request ID Template')"
                    dense outlined hide-details
                  ></v-text-field>
                </v-col>
                  <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.request_id_suffix"
                    :label="$t('Request ID Suffix')"
                    :placeholder="$t('Request ID Suffix')"
                    dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      type="number"
                      v-model.number="editedItem.time_frame_to_deliver"
                      :label="$t('Timeframe to deliver')"
                      :placeholder="$t('Timeframe to deliver')"
                      dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      type="number"
                      v-model.number="editedItem.months_before_purge"
                      :label="$t('Months before purge')"
                      :placeholder="$t('Months before purge')"
                      dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      type="number"
                      v-model.number="editedItem.consult_time_frame"
                      :label="$t('Consultation Time frame')"
                      :placeholder="$t('Consultation Time frame')"
                      dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="editedItem.standard_fee"
                      :label="$t('Standard Fee')"
                      :placeholder="$t('Standard Fee')"
                      dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="editedItem.include_in_statistics"
                      :label="$t('Include in Statistics')"
                      :placeholder="$t('Include in Statistics')"
                      dense outlined hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                      :items="clauses()"
                      v-model="editedItem.clause_set"
                      :label="$t('clauseset')"
                      :placeholder="$t('clauseset')"
                      dense outlined hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                      :items="editable"
                      v-model="editedItem.editable_due_date"
                      :label="$t('duedateditable')"
                      :placeholder="$t('duedateditable')"
                      dense outlined hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close">{{$t('cancel')}}</v-btn>
            <v-btn :disabled="isDisable" class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081"  
        :headers="headers"
        :items="types"
        sort-by="id"
        class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
        :search="search.searchWord"
        :loading="loading" 
        :loading-text="$t('Loading')"
        :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
        >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axcessApi from "@/plugins/axcessApi";
export default {
  props: ["request"],
  data() {
    return {
      max: 255,
      dialog: false,
      search: "",
      error: "",
      errored: false,
      editedIndex: -1,
      editedItem: {
        organization: 1,
      },
      defaultItem: {
        organization: 1,
      },
      classes: ["Protected", "Secret", "Top Secret"],
      rules: [
        (v) => !!v || this.$t("required"),
        (v) => v > 0 || this.$t("Value should be bigger than 0"),
      ],
      headers: [
        {
          text: this.$t("ID"),
          value: "id"
        },
        {
          text: this.$t("requesttype"),
          value: "request_type"
        },
        {
          text: this.$t("description en"),
          value: "description_en"
        },
        {
          text: this.$t("description fr"),
          value: "description_fr"
        },
        { text: "", value: "action", sortable: false }
      ],
       editable:[{ text: this.$t("Yes"), value: 1 },
                 { text: this.$t("No"), value: 0 }]
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("New Request Type") : this.$t("Edit Request Type");
    },
    isDisable() {
      return (
        !this.editedItem.request_type
      );
    },
     ...mapState({
      types: "types"
    }),
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
    })
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    updateSerialNumber(){

    },
    clauses(){
      return this.configurations.filter( filter => filter.LookUpName == "ClauseSet" ).map( item => {
        let arr = [];
        arr['text'] = item.LookUpStringE;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },
    security(){
      return this.configurations.filter( filter => filter.LookUpName == "Security" ).map( item => {
        let arr = [];
        arr['text'] = item.LookUpStringE;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },
    editItem(item) {
      this.editedIndex = this.types.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.types.indexOf(item);

      if (
          confirm(this.$t("Are you sure you want to delete this item?")) &&
        this.types.splice(index, 1)
      ) {
        try {
          await axcessApi.deleteRequest("/requesttype/" + item.id);
          let color="dark";
          let alert="itemRemovedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        } catch (error) {
          this.error = error.response.data;
          this.errored = true;
        }
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      console.log(this.editedItem.time_frame_to_deliver);
      if (this.editedIndex > -1) {
        try {
          const response = await axcessApi.patchRequest(
            "/requesttype/" + this.editedItem.id,
            this.editedItem
          );
          Object.assign(this.types[this.editedIndex], response.data);
          this.close();
          let color="dark";
          let alert="itemUpdatedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        } catch (error) {
          this.error = error.response.data;
          this.errored = true;
        }
      } else {
        try {
          const response = await axcessApi.postRequest("/requesttype", this.editedItem);
          this.types.push(response.data);
          this.close();
          let color="success";
          let alert="itemAddedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        } catch (error) {
          this.error = error.response.data;
          this.errored = true;
        }
      }
    },
    ...mapActions(['resetSerialNumber'])
  }
};
</script>